import { render } from "./TechnicalSupport.vue?vue&type=template&id=18316376"
import script from "./TechnicalSupport.ts?vue&type=script&lang=ts"
export * from "./TechnicalSupport.ts?vue&type=script&lang=ts"

import "./technicalSupport.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "18316376"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('18316376', script)) {
    api.reload('18316376', script)
  }
  
  module.hot.accept("./TechnicalSupport.vue?vue&type=template&id=18316376", () => {
    api.rerender('18316376', render)
  })

}

script.__file = "src/views/TechnicalSupport/TechnicalSupport.vue"

export default script